import React         from 'react';
import { graphql }   from 'gatsby';
import Img           from 'gatsby-image';
import { Box, Flex } from '@chakra-ui/react';
import Breakpoint    from 'react-socks';
import { Helmet }    from 'react-helmet';
import { Link }      from 'gatsby';

import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

import LiveAnnouncements  from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Display            from '@interness/theme-twentytwenty/src/components/Display/Display';
import Mounted            from '@interness/web-core/src/components/structure/Mounted/Mounted';
import Boxgroup           from '@interness/theme-twentynineteen/src/components/Boxgroup/Boxgroup';
import ConditionalWrapper from '@interness/web-core/src/components/structure/ConditionalWrapper/ConditionalWrapper';
import Carousel           from '@interness/web-core/src/components/media/Carousel/Carousel';
import CallToAction      from '@interness/theme-twentytwenty/src/components/CallToAction/CallToAction';

function IndexPage(props) {
  return (
    <>
      <Helmet>
        <meta name="thumbnail" content={props.data.googleThumbnail.media[0].file.localFile.childImageSharp.fluid.src}/>
      </Helmet>
      <Carousel>
        {props.data.headerImages.images.map((image) => (
          <ConditionalWrapper key={image.file.localFile.name}
                              condition={image.file.metadata && image.file.metadata.link_to}
                              wrapper={(children) => <Link to={image.file.metadata.link_to}>{children}</Link>}>
            <Img fluid={image.file.localFile.childImageSharp.fluid}
                 alt={image.file.localFile.name}/>
          </ConditionalWrapper>
        ))}
      </Carousel>

      {/*<RandomBrandHeaderImages count={3}/>*/}
      <Mounted>
        <Breakpoint large up>
          <Boxgroup floating={false}/>
          <Spacer/>
        </Breakpoint>
      </Mounted>
      <Wrapper width={1024}>
        <section>
          <Spacer/>
          <LiveAnnouncements/>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Hoischen</Heading>
          <Flex flexWrap="wrap" maxWidth="960px" m="auto" width="95%">
            <Box width={['100%', '100%', '65%', '65%']}>
              <Box pr={[0, 0, 12]} textAlign="justify">
                <p>
                  Seien Sie herzlich willkommen bei Juwelier Hoischen im Herzen Westfalens.</p>
                <p>
                  Seit nunmehr 110 Jahren erfüllen wir hier in Soest Ihre Wünsche bei Trauringen, Eheringen und
                  Verlobungsringen auf traditionelle Art und Weise und mit individuellen Anfertigungen und persönlicher
                  Beratung. <br/>
                  In unserem fein ausgewählten Sortiment finden Sie sowohl erlesenen als auch top aktuellen Schmuck,
                  moderne und klassische Uhren und extravagante Accessoires. Hier wird kaufen zum Erlebnis.<br/>
                  Es ist unsere Leidenschaft und unser fortwährendes Bestreben, außergewöhnliche Trauringe, Schmuck,
                  Uhren und einen Service auf höchstem Niveau zu bieten.<br/>
                  Kompetente und persönliche Beratung rundum individuelle Anfertigungen bei Trauringen und Schmuck ist
                  für das Team um Georg Hoischen selbstverständlich.<br/>
                  Für unsere interessierten Hochzeitspaare halten wir ein umfangreiches Sortiment namhafter
                  Trauring-Hersteller bereit.<br/>
                  Wir laden Sie herzlich ein, bei einer angenehmen Beratung Ihre Lieblingsringe auszuwählen,
                  anzuprobieren, nach Ihren Wünschen zu gestalten und zu kaufen.<br/>
                  Unsere Trauringkonfiguratoren erlauben eine Vielzahl von Möglichkeiten und mit wenigen Klicks erleben
                  Sie wie wir Ihre Traumringe virtuell erschaffen.<br/><br/>
                  Wir freuen uns auf Ihren Besuch in unserem Geschäft in der Brüderstr. 14 in Soest, wo wir uns in
                  entspannter Atmosphäre ausgiebig Zeit für Ihre Wünsche nehmen möchten.<br/>
                  So sind Ihre wertvollen Stücke bei unserem Uhrmachermeister in unserer Meisterwerkstatt gut aufgehoben.
                </p>
                <p>
                  Bis bald in Soest,<br/>Ihre Familie Hoischen
                </p>
              </Box>
            </Box>
            <Box width={['100%', '100%', '30%', '30%']}>
              <Box rounded={'2xl'}
                   boxShadow={'2xl'}
                   overflow={'hidden'}>
                <Img fluid={props.data.media.media[0].file.localFile.childImageSharp.fluid}/>
              </Box>
            </Box>
          </Flex>
        </section>
        <Spacer/>
        <section>
          <Heading tag="h2">Erleben Sie die Vielfalt unserer Markenwelt</Heading>
          <Display order={['wedding_rings', 'jewelry', 'watches', 'accessories']}/>
        </section>
        <Spacer/>
        <section>
          <Heading tag="h2">Altgold Service</Heading>
          <Box textAlign="center">
            <ul style={{ listStyleType: 'none' }}>
              <li>Wir kaufen Altgold aus vielen unterschiedlichen Bereichen an</li>
              <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken</li>
              <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen</li>
              <li>Altgoldankauf ist Vertrauenssache</li>
              <li>Verkauf von Edelmetallen in Form von Barren und Münzen.</li>
            </ul>
          </Box>
        </section>
        <Spacer/>
      </Wrapper>
      <CallToAction />
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query IndexQuery {
        media: directusMediaCollection(name: {eq: "home"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        googleThumbnail: directusMediaCollection(name: {eq: "google-thumbnail"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        headerImages: directusMediaCollection(name: {eq: "home-header"}) {
            name
            images: media {
                file {
                    metadata {
                        link_to
                    }
                    localFile {
                        name
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
